<template>
    <div class="card">
        <slot />
    </div>
</template>
<script>

export default {
    name: 'Card'
}
</script>
<style scoped>
    .card {
        background: #f8f8f8;
        padding: 1rem;
        color: #2f3542;
        max-width: 280px;
        /* border-radius: 0.4rem; */
        /* box-shadow: 0 1px 0 rgba( 9, 30, 66, 0.25); */
        /* box-shadow: 0 0.1rem 0.2rem 0 rgba(33, 33, 33, 0.9); */
        margin-bottom: 0.8rem;
        cursor: pointer;
    }
</style>