<template>
  <div>
        {{ company._id }}
  </div>
</template>

<script>
export default {
    data() {
        return {
            company: ''
        }
    },
    methods: {
        getId(id) {
            this.$tallos_admin_api.defaults.headers.common[ "Authorization"] =
				`Bearer ${this.$store.getters.tokenJWT}`;

			this.$tallos_admin_api
				.get(`companies/5e32e8c7578025070d6be064`)
				.then(res => {
                    this.company = res.data.company
                    this.company['message'] = res.data.last_message
                })
				.catch(e => {
					this.$toasted.global.defaultError({msg: 'Empresa não encontrada.'})
					this.$router.push({ path: '/admin/companies' })
				})
        },
    },
    mounted() {
        this.getId()
    }
}
</script>

<style scoped>
.content-wrapper {
    background-color: #fff;
    margin-left: 0;
}

.vertical-line {
    display: block;
    height: 100%;
    border-right: 4px solid rgb(168, 168, 168);
}

.circle {
    display: block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
}
</style>
